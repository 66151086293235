import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async rowRonda (Vue, idronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idronda', idronda)
    const resp = await Vue.$api.call('ronda.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectRondaPunto (Vue, idronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idronda', idronda)
    const resp = await Vue.$api.call('rondaPunto.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectRondaPuntoCheck (Vue, idsrondaPunto) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idronda_punto', idsrondaPunto)
    const resp = await Vue.$api.call('rondaPuntoCheck.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async updateRondaPunto (Vue, idrondaPunto, fecha, latitud, longitud, idvigilante) {
    await Vue.$api.call(
      'rondaPunto.update',
      {
        values: {
          'idronda_punto': idrondaPunto,
          fecha,
          latitud,
          longitud,
          idvigilante,
        }
      }
    )
  },
  async selectAdjuntoPuntoCheck (Vue, idspuntoCheck, esTecnico, esComercial, esVigilante) {
    const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idspuntoCheck, TABLA.ps_check.idtabla, esTecnico, esComercial, esVigilante, true)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async updatePsCheckResultado (Vue, idpsCheck, resultado, fecha, idvigilante) {
    await Vue.$api.call(
      'rondaPuntoCheck.update',
      {
        values: {
          'idps_check': idpsCheck,
          resultado,
          fcheck: fecha,
          idvigilante,
        }
      }
    )
  },
  async updatePsCheckObservaciones (Vue, idpsCheck, observaciones) {
    await Vue.$api.call(
      'rondaPuntoCheck.update',
      {
        values: {
          'idps_check': idpsCheck,
          observaciones,
        }
      }
    )
  },
  async updatePunto (Vue, idpunto, codigoScanner) {
    await Vue.$api.call(
      'punto.update',
      {
        values: {
          'idpunto': idpunto,
          'codigo_scanner': codigoScanner
        }
      }
    )
  },
  async finalizarRonda (Vue, idronda) {
    await Vue.$api.call(
      'ronda.finalizar', { 'idronda': idronda }
    )
  },
  async selectVigilante (Vue, idvigilante) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
    const resp = await Vue.$api.call(
      'vigilante.select',
      {
        filter: apiFilter,
      },
    )
    return resp.data.result.dataset[0]
  },
}
