<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      back
      @click-option="clickToolbarOption"
    />
    <b10-page-content
      :padding="3"
    >
      <v-stepper
        v-if="currentStep"
        vertical
        :value="currentStep"
        class="elevation-0"
      >
        <div
          v-for="(punto, puntoIndex) in rondaPuntos"
          :key="punto.idronda_punto"
        >
          <v-stepper-step
            editable
            :step="punto.punto_codigo"
            :color="puntoColor(punto)"
            :complete="!!punto.fecha"
            :edit-icon="$vuetify.icons.values.check"
          >
            {{ punto.punto_descripcion }}
            <small
              v-show="punto.idvigilante"
            >{{ punto.nombre_vigilante }}</small>
          </v-stepper-step>
          <v-stepper-content
            :step="punto.punto_codigo"
          >
            <v-alert
              type="info"
              :value="!!punto.mronda_punto_mensaje"
            >
              {{ punto.mronda_punto_mensaje }}
            </v-alert>
            <v-alert
              color="warning"
              dark
              :value="!punto.codigo_scanner && hasNFC"
            >
              <v-row align="center">
                <v-col class="grow">
                  Sin código asociado
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    outlined
                    small
                    @click.stop="clickAsociarCodigoScanner(puntoIndex)"
                  >
                    Asociar
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-list
              v-if="punto.checks.length > 0"
            >
              <ps-check-list-item
                v-for="check in punto.checks"
                :key="check.idps_check"
                :item="check"
                :punto="punto"
                @change-resultado="changeResultado"
                @click-observaciones="clickObservaciones"
                @click-adjuntos="clickAdjuntos"
              />
            </v-list>
            <v-btn
              v-if="punto.latitud && punto.longitud"
              block
              color="green"
              dark
              @click.stop="clickIrMapaPunto(punto)"
            >
              <v-icon
                left
              >
                {{ $vuetify.icons.values.mapLocation }}
              </v-icon>
              ({{ punto.latitud.toFixed(6) }}, {{ punto.longitud.toFixed(6) }})
            </v-btn>
            <v-btn
              v-else-if="punto.fecha"
              block
              color="warning"
              dark
            >
              <v-icon
                left
              >
                {{ $vuetify.icons.values.warning }}
              </v-icon>
              Sin geolocalización
            </v-btn>
          </v-stepper-content>
        </div>
      </v-stepper>
      <b10-bottom-navigation
        :buttons="bottomNavBarButtons"
        @click-bottom-nav-bar-button="clickBottomNavBarButton"
      />
    </b10-page-content>
    <b10-qr-reader
      :showing.sync="showingDialogs.scanQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
    <ps-check-observaciones
      :showing.sync="showingDialogs.psCheckObservaciones"
      :item="psCheckActual"
      :punto="puntoActual"
      @set-observaciones="setObservaciones"
    />
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import Data from './RondaRealizarData'
import _ from '@/utils/lodash'
import { captureGeolocationCheck, continuarConGeolocalizacion, getLatLongURL } from '@/utils/maps'
import { hasNFC, scanNFC } from '@/utils/nfc'
import { get } from 'vuex-pathify'
import { currentDateTime } from '@/utils/date'
import { TABLA } from '@/utils/consts'
import PsCheckListItem from './components/PsCheckListItem'
import PsCheckObservaciones from './components/PsCheckObservaciones'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    PsCheckListItem, PsCheckObservaciones,
  },
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        finalizarRonda: {
          title: 'Finalizar ronda',
          visible: true,
          icon: 'check'
        },
        annadirNovedad: {
          title: 'Añadir novedad',
          visible: true,
          icon: 'add'
        },
        adjuntos: {
          title: 'Adjuntos',
          visible: true,
          icon: 'attach'
        }
      },
      hasNFC: hasNFC(),
      ronda: null,
      rondaPuntos: [],
      punto: '',
      showingDialogs: {
        scanQR: false,
        psCheckObservaciones: false,
      },
      currentStep: '',
      psCheckActual: {},
      puntoActual: {},
      vigilante: {},
    }
  },
  computed: {
    presencia: get('presencia'),
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    presenciaIdempresa: get('presencia/idempresa'),
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
    puntosPendientes () {
      return _.filter(this.rondaPuntos, (punto) => {
        return punto.fecha === null
      })
    },
    cantChecksPendientesRequeridos () {
      let pendientesRequeridos = this.rondaPuntos.reduce(
        (accum, currVal) => {
          return accum + _.filter(currVal.checks, (check) => {
            return (check.resultado === null) && (check.requerido)
          }).length
        },
        0,
      )
      return pendientesRequeridos
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Realizar ronda'
      this.loadRonda(this.routeParams.idronda)
      this.loadNavigationBottomButtons()
    },
    async loadNavigationBottomButtons () {
      this.initNavigationBottom()
      this.addNavigationBottomButton(
        'NFC', 'nfc', 'nfc', this.hasNFC
      )
      this.addNavigationBottomButton(
        'QR', 'qr', 'qr'
      )
    },
    async loadRonda (idronda) {
      this.ronda = await Data.rowRonda(this, idronda)
      this.title = `Iniciada el ${this.$options.filters.shortDate(this.ronda.fcomienzo)} a las ${this.$options.filters.shortTime(this.ronda.fcomienzo)}`
      const [datasetPuntos] = await Data.selectRondaPunto(this, idronda)
      let primerPuntoCodigo = ''
      const idpuntos = _.map(datasetPuntos, 'idronda_punto')
      const datasetPSCheck = await Data.selectRondaPuntoCheck(this, idpuntos)
      const idsPSCheck = _.map(datasetPSCheck, 'idps_check')
      const adjuntos = await Data.selectAdjuntoPuntoCheck(
        this,
        idsPSCheck,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      for (const punto of datasetPuntos) {
        if (primerPuntoCodigo === '') {
          primerPuntoCodigo = punto.punto_codigo
        }
        const datasetChecks = _.filter(datasetPSCheck, {idronda_punto: punto.idronda_punto})
        if (datasetChecks) {
          for (let check of datasetChecks) {
            check.con_adjuntos = !!(_.find(adjuntos, { id: check.idps_check }))
          }
          punto.checks = datasetChecks
        } else {
          punto.checks = []
        }
      }
      this.rondaPuntos = datasetPuntos
      this.currentStep = primerPuntoCodigo
      this.vigilante = await Data.selectVigilante(this, this.usuarioIdvigilante)
    },
    async clickLeerNFC () {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          this.punto = tag.tagID
          this.$alert.showSnackbarSuccess('Etiqueta NFC leída')
          this.changePuntoControl()
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
    async changePuntoControl () {
      if (this.punto) {
        const punto = _.find(this.rondaPuntos, { 'codigo_scanner': this.punto })
        if (punto) {
          // marcar punto realizado
          this.currentStep = punto.punto_codigo
          if (punto.fecha) {
            this.$alert.showSnackbarError(
              `El punto de control ya fue realizado
              el ${this.$options.filters.shortDate(punto.fecha)}
              a las ${this.$options.filters.shortTime(punto.fecha)}
              `
            )
          } else {
            let geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
            if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, geolocalizacion, false)) {
              punto.fecha = currentDateTime()
              punto.latitud = geolocalizacion?.lat
              punto.longitud = geolocalizacion?.lng
              punto.idvigilante = this.usuarioIdvigilante
              punto.nombre_vigilante = this.vigilante.nombre_vigilante
              await Data.updateRondaPunto(
                this, punto.idronda_punto, punto.fecha, punto.latitud, punto.longitud, punto.idvigilante
              )
              if (this.puntosPendientes.length === 0) {
                this.$nextTick(() => { this.finalizarRonda() })
              }
            }
          }
        } else {
          this.$alert.showSnackbarError('Punto de control incorrecto')
        }
        this.punto = ''
      }
    },
    clickIrMapaPunto (punto) {
      window.open(getLatLongURL(punto.latitud, punto.longitud), '_blank')
    },
    async finalizarRonda () {
      let continuar = true
      if (this.cantChecksPendientesRequeridos === 0) {
        if (this.puntosPendientes.length > 0) {
          continuar = await this.$alert.showConfirm(
            `Hay ${this.puntosPendientes.length}
            ${this.$options.filters.pluralize('punto', this.puntosPendientes.length, 's')}
            ${this.$options.filters.pluralize('pendiente', this.puntosPendientes.length, 's')}.
            ¿Deseas finalizar la ronda?`
          )
        } else {
          continuar = await this.$alert.showConfirm('Ya has leído todos los puntos de control. ¿Deseas finalizar la ronda?')
        }
        if (continuar) {
          await Data.finalizarRonda(this, this.routeParams.idronda)
          await this.$dirty.modified(this.$dirty.ENTITIES.remote.ronda, this.routeParams.idronda)
          this.$appRouter.go(-1)
        }
      } else {
        this.$alert.showSnackbarError(`Hay ${this.cantChecksPendientesRequeridos} chequeos requeridos que no han sido realizados`)
      }
    },
    async clickToolbarOption(option) {
      switch(option) {
      case this.toolbarOptions.finalizarRonda:
        this.finalizarRonda()
        break
      case this.toolbarOptions.annadirNovedad:
        const parteNovedad = await this.$online.parteNovedad.getOrCreateParteNovedad(this.ronda.idpuesto_servicio, this.presenciaIdempresa)
        if (parteNovedad) {
          this.$appRouter.push({
            name: 'partesnovedad__lparte-novedad-add',
            params: {
              idparte_novedad: parteNovedad.idparte_novedad
            }
          })
        }
        break
      case this.toolbarOptions.adjuntos:
        this.$appRouter.push({
          name: 'ficheros__rondas-realizadas__fichero-list',
          params: {
            id: this.routeParams.idronda,
            idtabla: TABLA.ronda.idtabla,
          },
        })
        break
      default:
        console.error('Opción no configurada.')
        break
      }
    },

    async clickAsociarCodigoScanner (puntoIndex) {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          const formattedTag = tag.tagID
          Data.updatePunto(this, this.rondaPuntos[puntoIndex].idpunto, formattedTag)
          this.$set(this.rondaPuntos[puntoIndex], 'codigo_scanner', formattedTag)
          this.$alert.showSnackbarSuccess('Etiqueta NFC asociada al punto de control')
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
    clickQR () {
      this.showingDialogs.scanQR = !this.showingDialogs.scanQR
    },
    async decodeQR (value) {
      if (value) {
        this.punto = value
        this.changePuntoControl()
      }
    },
    async changeResultado (value) {
      await Data.updatePsCheckResultado(this, value.item.idps_check, value.resultado, currentDateTime(), this.usuarioIdvigilante)
      const punto = _.find(this.rondaPuntos, { 'idronda_punto': value.punto.idronda_punto })
      const check = _.find(punto.checks, { 'idps_check': value.item.idps_check})
      this.$set(check, 'resultado', value.resultado)
      this.$set(check, 'idvigilante', this.usuarioIdvigilante)
      this.$set(check, 'nombre_vigilante', this.vigilante.nombre_vigilante)
    },
    clickObservaciones (value) {
      this.psCheckActual = value.item
      this.puntoActual = value.punto
      this.showingDialogs.psCheckObservaciones = true
    },
    clickAdjuntos (value) {
      this.$appRouter.push({
        name: 'ficheros__rondas-realizadas__ps-check__fichero-list',
        params: {
          id: value.item.idps_check,
          idtabla: TABLA.ps_check.idtabla,
        },
      })
    },
    async setObservaciones (value) {
      await Data.updatePsCheckObservaciones(this, value.item.idps_check, value.formData.observaciones)
      const punto = _.find(this.rondaPuntos, { 'idronda_punto': value.punto.idronda_punto })
      const check = _.find(punto.checks, { 'idps_check': value.item.idps_check})
      this.$set(check, 'observaciones', value.formData.observaciones)
    },
    puntoColor (punto) {
      if (punto.fecha) {
        return colors.green.base
      }
    },
    clickBottomNavBarButton (data) {
      switch (data.button.name) {
      case 'nfc':
        this.clickLeerNFC()
        break
      case 'qr':
        this.clickQR()
        break
      default:
        console.error(`La opción ${data.button.name} no está contemplada`)
      }
    },
  },
}
</script>

<style scoped>
.chip-pendiente-realizado {
  text-shadow: none;
}
.input-punto >>> input {
  -webkit-text-security: disc;
}
.v-stepper__content {
  padding-left: 0;
}
</style>
